import React from "react";

function About() {
  return (
    <>
      <div className="abt-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="abthead-text">
                <h1>
                  <span style={{ color: "#fff" }}>About</span> Cabtify
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-abt-section">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="abthome-img">
                <img src="assets/images/abt-img.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-abt-text">
                <h2 className="mt-3">About Us</h2>
                <p>
                  Welcome to Cabtify where convenience, safety, and reliability
                  converge to redefine your travel experience. We are a leading
                  rideshare platform committed to seamlessly connecting riders
                  with trusted drivers, all with the tap of a button. Since our
                  inception, we've been dedicated to revolutionizing
                  transportation by offering a convenient and efficient solution
                  to getting you where you need to be, when you need to be
                  there.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whychoos-section mb-2">
        <div className="conatiner">
          <div className="row justify-content-center ">
            <div className="col-sm-3 col-md-6 col-lg-3 mb-3">
              <div className="mession-text">
                <h3>Mission</h3>
                <p>Making travel easy for everyone.</p>
              </div>
            </div>
            <div className="col-sm-3 col-md-6 col-lg-3 mb-3">
              <div className="mession-text">
                <h3>Vision</h3>
                <p>
                  To create a world where getting around is effortless and
                  inclusive.&nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="afterfooter-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="afterfooter-text">
                <p>More than just a ride, it's an experience!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
