import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

const TermsService = () => {
  return (
    <Container className="mt-4">
      <h3> Terms of Service</h3>

      <p style={{ fontSize: 20, fontWeight: "500" }}>
        Effective Date:{" "}
        <span style={{ fontSize: 18, fontWeight: "100" }}>
          {" "}
          March 15, 2024{" "}
        </span>
      </p>

      <p>
        Welcome to Cabtify, a rideshare application operated by Cabtify
        (&quot;Cabtify,&quot; &quot;we,&quot; &quot;our,&quot; or
        &quot;us&quot;). By using the Cabtify app or any of our services, you
        agree to comply with and be bound by the following terms and conditions
        (&quot;Terms of Service&quot; or &quot;Agreement&quot;). Please read
        these terms carefully before using our services.
      </p>

      <Row className="mb-3">
        <Col>
          <h5>1. Acceptance of Terms:</h5>
          <p>
            By accessing or using our platform, you agree to these terms and
            conditions, as well as our Privacy Policy. If you do not agree with
            any of these terms, you may not use our services.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>2. Description of Service:</h5>
          <p>
            Cabtify provides a platform that connects passengers with drivers
            for the purpose of transportation. Our services include the Cabtify
            mobile app and any other related services provided by Cabtify.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>3. Eligibility:</h5>
          <p>
            To use the Cabtify app, you must be at least 18 years old and
            capable of entering into a legally binding agreement. By using our
            services, you represent and warrant that you meet these eligibility
            requirements.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>4. User Accounts:</h5>

          <ul>
            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Registration:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                To use the Cabtify app, you must create an account by providing
                accurate and complete information. You are responsible for
                maintaining the confidentiality of your account credentials and
                for all activities that occur under your account.
              </span>
            </li>

            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Account Security:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                You agree to notify us immediately of any unauthorized use of
                your account. Cabtify will not be liable for any loss or damage
                arising from your failure to protect your account information.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>5. Use of Services: </h5>
          <ul>
            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Driver Requirements:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                All drivers must meet Cabtify&#39;s requirements, including
                background checks, vehicle inspections, and other criteria
                established by Cabtify.
              </span>
            </li>

            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Passenger Responsibilities:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                Users may not use the Cabtify app for any unlawful or harmful
                activities, including but not limited to harassment, fraud, or
                transportation of illegal goods.
              </span>
            </li>

            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Prohibited Activities:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                You agree to notify us immediately of any unauthorized use of
                your account. Cabtify will not be liable for any loss or damage
                arising from your failure to protect your account information.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>6. Payment:</h5>
          <ul>
            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Fare Calculation:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                Fares are calculated based on distance, time, and other factors.
                The fare estimate provided before the ride may vary from the
                final fare due to traffic, route changes, or other unforeseen
                circumstances.
              </span>
            </li>

            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Payment Method:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                Passengers must provide a valid payment method. By providing a
                payment method, you authorize Cabtify to charge the fare and any
                applicable fees to your payment method.
              </span>
            </li>

            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Cancellation Policy:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                If you cancel a ride after a driver has accepted it, you may be
                charged a cancellation fee.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>7. Dispute Resolution:</h5>
          <ul>
            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Arbitration:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                Any disputes arising out of or relating to these Terms of
                Service or the use of the Cabtify app will be resolved through
                binding arbitration, rather than in court, except that you may
                assert claims in small claims court if your claims qualify.
              </span>
            </li>

            <li className="mt-3  ">
              <span style={{ fontSize: 20, fontWeight: "500" }}>
                Governing Law:{" "}
              </span>
              <span style={{ fontSize: 14, fontWeight: "100" }}>
                This Agreement shall be governed by and construed in accordance
                with the laws of Minnesota, without regard to its conflict of
                law principles.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>8. Limitation of Liability:</h5>
          <p>
            Cabtify is not liable for any damages, injuries, or losses incurred
            during the use of our platform, including during rides arranged
            through the platform. Users are encouraged to review and understand
            their insurance coverage before using our services.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>9. Termination:</h5>
          <p>
            We reserve the right to terminate or suspend your account and access
            to the Cabtify app at our sole discretion, without notice, for
            conduct that we believe violates these Terms of Service or is
            harmful to other users, us, or third parties, or for any other
            reason.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>10. Changes to Terms:</h5>
          <p>
            Cabtify reserves the right to update or modify these terms and
            conditions at any time without prior notice. Any changes will be
            effective immediately upon posting to the platform. Users are
            responsible for regularly reviewing these terms for updates.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>9. Contact Information:</h5>
          <p>
            If you have any questions or concerns about these Terms of Service,
            please contact us at:
          </p>
          <p className="m-0 p-0">Cabtify</p>
          <p className="m-0 p-0">8900 Penn Ave S, 309, Bloomington, MN 55431</p>
          <p className="m-0 p-0">Hello@cabtify.com</p>
          <p className="m-0 p-0">(612) 990-0009</p>
        </Col>
      </Row>

      <p>
        By using the Cabtify app, you acknowledge that you have read,
        understood, and agree to be bound by these Terms of Service.
      </p>
    </Container>
  );
};

export default TermsService;
