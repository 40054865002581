import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/backend.css";
import "../../assets/css/all.min.css";
import { useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";

function Profile() {
  const personalDetails = useSelector((state) => state.auth.userInfo);
  const userInfo = useSelector((state) => state.auth);
  const { isLoading } = userInfo;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch mb-3">
            <div className="card-body ">
              <div className="d-flex justify-content-between align-items-center ">
                <h5 className="font-weight-bold">Passenger Profile</h5>
                {/* <Link
                  to="/drivers"
                  className="float-right btn btn-sm btn-primary"
                >
                  <i className="fa fa-angle-double-left" /> Back
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-block p-card mb-3">
              <div className="profile-box">
                <div className="profile-card rounded">
                  <img
                    src={personalDetails?.profileImageUrl}
                    alt="01.jpg"
                    className="avatar-100 rounded d-block mx-auto img-fluid mb-3"
                  />
                  <h3 className="font-600 text-white text-center mb-0">
                    {personalDetails?.firstName} {personalDetails?.lastName}
                  </h3>
                  <p className="text-white text-center mb-5">
                    <span className="text-capitalize badge bg-success ">
                      {personalDetails?.status}
                    </span>
                  </p>
                </div>
                <div className="pro-content rounded">
                  <div className="d-flex align-items-center mb-3 gap-3">
                    <div className="p-icon ml-3">
                      <i className="fas fa-envelope" />
                    </div>
                    <p className="mb-0 eml ">{personalDetails?.email}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <p className="mb-0">{personalDetails?.phone}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    <div className="p-icon mr-3">
                      <i
                        className={`fas fa-${personalDetails?.gender?.toLowerCase()}`}
                      />
                    </div>
                    <p className="mb-0">{personalDetails?.gender}</p>
                  </div>
                  {/* <div className="d-flex align-items-center mb-3 gap-3">
                    {personalDetails?.verification === "Verified" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-check`} />
                        </div>
                        <p className="mb-0">{personalDetails?.verification}</p>
                      </>
                    ) : personalDetails?.verification === "Pending" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fa fa-clock`} />
                        </div>
                        <p className="mb-0">
                          Verification {personalDetails?.verification}
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-ban`} />
                        </div>
                        <p className="mb-0">
                          Verification {personalDetails?.verification}
                        </p>
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
