import React, { useEffect, useState } from "react";
import { payments } from "@square/web-sdk";
import { SQUARE_APP_ID, SQUARE_LOCATION_ID } from "../constants";

const AddCard = () => {
  const [card, setCard] = useState(null);

  useEffect(() => {
    async function initializeCard() {
      const paymentsInstance = await payments(
        SQUARE_APP_ID,
        SQUARE_LOCATION_ID
      );
      const cardInstance = await paymentsInstance.card();
      await cardInstance.attach("#card-container");
      setCard(cardInstance);
    }

    initializeCard();
  }, []);

  const handlePaymentMethod = async () => {
    if (card) {
      try {
        const result = await card.tokenize();
        if (result.status === "OK") {
          const nonce = result.token;
          console.log("Card Nonce:", nonce);
          // Send the nonce to your backend to attach to a customer
        } else {
          console.error("Tokenization failed");
        }
      } catch (error) {
        console.error("Error creating card token:", error);
      }
    }
  };

  return (
    <div>
      <div id="card-container"></div>
      <button onClick={handlePaymentMethod}>Add Card</button>
    </div>
  );
};

export default AddCard;
