import React from "react";
import { Link } from "react-router-dom";

function TablePagination({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <Link className="page-link" to="" onClick={() => onPageChange(i)}>
            {i}
          </Link>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="d-flex border">
      <div className="flex-grow-1">
        <div
          className="dataTables_info"
          id="leads-table_info"
          role="status"
          aria-live="polite"
        >
          Showing {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)}{" "}
          to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems}{" "}
          entries
        </div>
      </div>
      <div>
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="leads-table_paginate"
        >
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <Link
                aria-label="Previous"
                aria-current="page"
                className="page-link"
                to=""
                onClick={() => onPageChange(currentPage - 1)}
              >
                <span aria-hidden="true">Previous</span>
                <span className="sr-only">Previous</span>
              </Link>
            </li>
            {renderPageNumbers()}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <Link
                aria-label="Next"
                aria-current="page"
                className="page-link"
                to=""
                onClick={() => onPageChange(currentPage + 1)}
              >
                <span aria-hidden="true">Next</span>
                <span className="sr-only">Next</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TablePagination;
