import React from "react";
import { Link, NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      {/* <div className="topbar-section">
        <nav
          className="navbar navbar-expand-lg navbar-light d-lg-block bg-dark"
          id="templatemo_nav_top"
        >
          <div className="container text-light">
            <div className="w-100 d-flex justify-content-end">
              <div className="list-inline">
                <ul>
                  <li>
                    <Link to="/login">log In</Link>
                  </li>
                  <li className="border-end  mx-3" />
                  <li>
                    <Link href="signup.html">Sign Up</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div> */}
      <div className="paraxdo-header-section">
        <div className="container">
          <div className="row">
            <div className="paraxdo-header-img">
              <nav className="navbar navbar-expand-lg navbar-dark bg-dark-new">
                <div className="container-fluid">
                  <Link className="navbar-brand w-30 " to="/">
                    <img
                      src="assets/images/header-logo.png"
                      id="logsedf"
                      alt=""
                    />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo03"
                    aria-controls="navbarTogglerDemo03"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarTogglerDemo03"
                  >
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link public"
                          activeClassName="active"
                          id="home-col"
                          to="/"
                        >
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link public"
                          activeClassName="active"
                          to="/about-us"
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link public"
                          activeClassName="active"
                          to="/drivers"
                        >
                          Driver
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link public "
                          activeClassName="active"
                          to="/riders"
                        >
                          Riders
                        </NavLink>
                      </li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                      <Link
                        to="/login"
                        className="btn btn-outline-success my-2 my-sm-0"
                        type="submit"
                      >
                        Book a Ride
                      </Link>
                    </form>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
