"use client";
import React from "react";
import { useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useGetContactAPIMutation } from "../../redux/services/contactAPI";
import { notifyFailure, notifySuccess } from "../../components/toast";

const isLoading = false;
function ContactUs() {
  //   const [CreateContact, { isLoading, isError, error }] =
  //     usePostContactMutation();

  const [formData, setFormData] = useState({
    company: "",
    email: "",
    message: "",
    name: "",
    phone: "",
    subject: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  console.log(formData, "formData");

  const [CreateContact, { data }] = useGetContactAPIMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await CreateContact({
        ...formData,
      }).unwrap();
      notifySuccess("Your query is submitted!");
      setFormData({
        company: "",
        email: "",
        message: "",
        name: "",
        phone: "",
        subject: "",
      });
    } catch (error) {
      notifyFailure(error?.data?.message);
    }
  };
  return (
    <>
      <div className="contact-slider">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="abthead-text">
                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contfrom-section bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 mb-4">
              <div className="formtext">
                <h3>Get in touch with us!</h3>
              </div>
            </div>
            <div className="col-md-10">
              <div className="">
                <form onSubmit={submitHandler}>
                  <div className="row justify-content-center">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="YourName" className="form-label lab-text">
                        Your Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="YourName"
                        placeholder=""
                        aria-label="Your name"
                        name="name"
                        onChange={handleChange}
                        value={formData.name}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="YourCompany"
                        className="form-label lab-text"
                      >
                        Your Company
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        placeholder=""
                        aria-label="Your Company"
                        name="company"
                        onChange={handleChange}
                        value={formData.company}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="Emailtext"
                        className="form-label lab-text"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="Emailtext"
                        placeholder=""
                        aria-label="email"
                        name="email"
                        onChange={handleChange}
                        value={formData.email}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="phoneNum1"
                        className="form-label lab-text"
                      >
                        Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneNum1"
                        placeholder=""
                        aria-label="Phone"
                        name="phone"
                        onChange={handleChange}
                        value={formData.phone}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="Subject" className="form-label lab-text">
                        Subject
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Subject"
                        placeholder=""
                        name="subject"
                        onChange={handleChange}
                        value={formData.subject}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="comment" className="form-label lab-text">
                        Your Message
                      </label>
                      <textarea
                        className="form-control"
                        rows={7}
                        id="comment"
                        name="message"
                        placeholder=""
                        defaultValue={""}
                        onChange={handleChange}
                        value={formData.message}
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="form-btn d-flex justify-content-center">
                        <CustomButton
                          title={"Submit Now"}
                          isLoading={isLoading}
                          className="btn btn-secondary"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
