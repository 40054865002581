import TableHead from "./tableHead";
import TablePagination from "./tablePagination";
import TableRow from "./tableRow";
import DataNotFound from "../../assets/images/table-data-not-found.jpg";
import { useEffect, useState } from "react";

function Table({ title, columns, dataSource, onRowClick, isLoading }) {
  //   const { title, columns, dataSource, onRowClick, isLoading } = props;
  // let searchList = columns.filter((x) => x.searchAble);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);

  // Calculate the range of items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentItems(dataSource.slice(indexOfFirstItem, indexOfLastItem));
  }, [indexOfFirstItem, indexOfLastItem, dataSource]);

  console.log("current items==>", currentItems);
  return (
    <div className="d-flex flex-column w-tables rounded bg-white card card-block">
      <TableHead
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setCurrentPage={setCurrentPage}
      />
      {/* <h2>{title}</h2> */}
      {isLoading &&
      dataSource &&
      Array.isArray(dataSource) &&
      dataSource.length == 0 ? (
        <img
          width="40%"
          className="mx-auto"
          src="https://hackernoon.com/images/0*4Gzjgh9Y7Gu8KEtZ.gif"
          alt="Loading..."
        />
      ) : dataSource && Array.isArray(dataSource) && dataSource.length == 0 ? (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            src={DataNotFound}
            width="200"
            className="mx-auto"
            alt="No Data Found :("
          />
          <p>Data not found</p>
        </div>
      ) : (
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      className="      
                     border-right"
                    >
                      S.No
                    </th>
                    {columns && Array.isArray(columns) && columns.length > 0
                      ? columns.map((x, i) => (
                          <th
                            className="
                           border-right"
                            key={i}
                          >
                            {x.displayName}
                          </th>
                        ))
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {currentItems &&
                  Array.isArray(currentItems) &&
                  currentItems.length > 0
                    ? currentItems.map((rowData, i) => (
                        <TableRow
                          srNo={indexOfFirstItem + i + 1}
                          key={i}
                          index={i}
                          rowData={rowData}
                          columns={columns}
                        />
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <TablePagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={dataSource.length}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
}
export default Table;
